import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import AngledImg from "../../components/images/advertising/angled"

export default function TableDrapes() {
    return (
        <>
            <Layout>
                <SEO title="Table Drapes" />
                <Display
                    title="Table Drapes"
                    description="Your custom, uniquely branded table clothes, runners and covers will turn your event or display from ho-hum to wow and add that touch of class to all of your events."
                    prev="/advertising/banners"
                    next="/advertising/tents"
                    image={<AngledImg />}
                />
            </Layout>                
        </>
    )
}
